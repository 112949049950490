import AOS from 'aos';
import 'slick-carousel'
import './news-globe.loader'
import './hyper-globe.min'
import "magnific-popup"

jQuery( document ).ready(function($) {

  // Mobile menu
  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('.menu-header-menu-container').slideToggle()
  })

  $('.menu-item-has-children').append('<button class="sub-menu-trigger"><i class="fas fa-chevron-down"></i></button>')

  $('.sub-menu-trigger').click(function(){
    $(this).parent().find('.sub-menu').slideToggle()
    $(this).toggleClass('active')
  })

  // Animations
  AOS.init({
    duration: 1000,
    once: true,
  });

  $('.slider-logo').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 2000,
    cssEase:'linear',
    infinite: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  $('.slider-videos').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  $('.slider-videos img').click(function(){
    const videoId = $(this).attr('data-youtube-code')
    $('.featured-video').attr('src', `https://www.youtube.com/embed/${videoId}`)
  })

  // Globe
  document.getElementById('news-globe').addEventListener('complete',function(){
    let speedAni;

    // slowly stop autorotate
    this.onpointerover = () => {
      if ( speedAni ) speedAni.cancel();
      speedAni = this.ani(
        {'--autorotate-speed':0}
      );
    };

    // slowly restart autorotate
    this.onpointerout = () => {
      if ( speedAni ) speedAni.cancel();
      speedAni = this.ani(
        {'--autorotate-speed':0.8}
      );
    };
  });

  $('.location-trigger').click(function(e){
    e.preventDefault()
    const popupTarget = $(this).attr('href')
    console.log(popupTarget)
    $(`.location-popup.${popupTarget}`).addClass('active')
  })

  $('.popup-close').click(function(e){
    e.preventDefault()
    $(this).parent().parent().parent().parent().removeClass('active')
  })

  $('.location-popup .gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Loading image #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
		}
	});

});