{
	
	let html = `
<hyper-globe id="news-globe" data-location="16.6761 -69.4642" data-version="10" data-comment="Example that stops autorotation on hover and changes marker image on hover." style="--preview-size:824px; --globe-scale:0.7; --globe-damping:0.5; --map-density:0.41; --map-height:0; --point-size:1.4; --point-color:#f1f1f8; --backside-opacity:0.3; --backside-transition:0.33; --marker-size:1; --title-position:1 0; --title-padding:1.1; --text-color:#ff0000; --text-size:0.8; --text-padding:0.5; --line-color:#999999; --line-offset:0.5; --preview-color:#c4c7ca; --backside-color:#74797b; --object-depth:0.5; --antarctica:true; --globe-latitude-limit:28.5; --autorotate:true; --autorotate-speed:0.8; --autorotate-delay:0.5; --islands:true; max-width: 1020px; --text-height:1.1; --line-thickness:1; --globe-background:url(&quot;soft-shadow.svg&quot;); --marker-image:url(&quot;red-square.svg&quot;); --point-color-blend:multiply;">
	<a data-location="26.3077 -81" title="Florida,
USA" slot="markers"></a>
	<a data-location="51.2308 -4.2857" title="UK" slot="markers" class=""></a>
<a data-location="-42.9231 172.6531" title="New Zealand" slot="markers" class=""></a>
<a data-location="15.2308 73.6364" title="India" slot="markers" class="">
</a></hyper-globe>
	`;
	
	let css = `
.marker-hover {
	--marker-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ4cHgiIGhlaWdodD0iNDhweCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OCA0ODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNGRjAwMDA7c3Ryb2tlOiNGRjAwMDA7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PHJlY3QgeD0iMTAiIHk9IjEwIiBjbGFzcz0ic3QwIiB3aWR0aD0iMjgiIGhlaWdodD0iMjgiLz48L3N2Zz4');
}
.marker-title {
	font-weight: bold;
}
`;
	
	let script = `
let speedAni;

// slowly stop autorotate
this.onpointerover = () => {
	if ( speedAni ) speedAni.cancel();
	speedAni = this.ani(
		{'--autorotate-speed':0}
	);
};

// slowly restart autorotate
this.onpointerout = () => {
	if ( speedAni ) speedAni.cancel();
	speedAni = this.ani(
		{'--autorotate-speed':0.8}
	);
};
`;
	
	
	if ( ! self.confGlobe ) {
		// get the script element
		let elem = document.currentScript;
		if ( elem && elem.isConnected && elem.closest('body') && elem.getAttribute('src') ) {
			
			// get baseurl from script src
			let url = new URL( elem.getAttribute('src'), self.location.href ).href;
			if ( url.startsWith('http') ) {		
				let baseurl = url.substr(0, url.lastIndexOf('/')+1);		
				
				// import hyper globe module once
				if ( ! self.hyperGlobe ) {
					self.hyperGlobe = true;
					import( baseurl + 'hyper-globe.min.js' );
				}			
				
				// inject html
				html = html.replace('<hyper-globe ', `<hyper-globe data-baseurl="${baseurl}" `);
				elem.insertAdjacentHTML('afterend', html);
				if (css)	elem.nextElementSibling.insertAdjacentHTML('afterend', `<style>${css}</style>`);
				if (script) elem.nextElementSibling.addEventListener('complete', new Function(script));
				
			} else {
				console.error('This script was used in the wrong way. It must be loaded via the https: or http: protocol.');
			}
			
		} else {
			console.error('This script was used in the wrong way. It must be a non-module script inside the body element.');
		}
	}	
	
}